import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation, Redirect } from 'react-router-dom';

import {
  MessageSquare,
  Share,
  Eye,
  Facebook as IconFacebook,
  Mail as IconEmail,
  Copy,
  SquarePen,
  Video,
} from 'lucide-react';
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { IconTwitterX } from '../../components';
import { IconWhatsApp } from '../../components';

// Contexts
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
// Utils
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import {
  isBookingProcess,
  isNegotiatedBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

// Global ducks (for Redux actions and thunks)
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

// Shared components
import {
  H4,
  Heading,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  ReviewRating,
  Modal,
  ResponsiveImage,
  IconArrowHead,
} from '../../components';

// Related components and modules
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
import CustomListingFields from './CustomListingFields';
import SectionRequirementsMaybe from './SectionRequirementsMaybe.js';
import SectionMeetingInstructionsMaybe from './SectionMeetingInstructionsMaybe.js';
import SectionLocationDescriptionMaybe from './SectionLocationDescriptionMaybe.js';
import SectionAdditionalInfoMaybe from './SectionAdditionalInfoMaybe.js';
import InquiryForm from './InquiryForm/InquiryForm';

import { requestPublishListingDraft } from '../EditListingPage/EditListingPage.duck.js';

import css from './ListingPage.module.css';
import ProviderSocialShareCallout from '../SocialSharePage/ProviderSocialShareCallout/ProviderSocialShareCallout.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MIN_LENGTH_FOR_LONG_WORDS = 20;
const MAX_MOBILE_SCREEN_WIDTH = 768;
const TOPBAR_HEIGHT_MOBILE = 106;
const TOPBAR_HEIGHT_DESKTOP = 86;

const { UUID } = sdkTypes;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    onPublishListingDraft,
  } = props;

  let query = useQuery();
  const isPreview = query.get('isPreview') === 'true';
  const [showReviewModal, setShowReviewModal] = useState(isPreview);
  const [showShareModal, setShowShareModal] = useState(false);

  const [shouldRedirectAfterReview, setRedirectAfterReview] = useState(false);
  const [publishError, setPublishError] = useState(false);
  const listingConfig = config.listing;

  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));
  const isVirtual = currentListing?.attributes?.publicData?.listingType === 'virtual';

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const firstImage =
    currentListing?.attributes?.publicData?.media &&
    currentListing?.attributes.publicData.media.length > 0
      ? currentListing.attributes.publicData.media[0]
      : null;

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith('listing-card'))
    : [];

  const listingPathParamType =
    isDraftVariant || isPreview ? LISTING_PAGE_PARAM_TYPE_DRAFT : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'media' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage staticContext={props.staticContext} />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const richDescription = (
    <span>
      {richText(description, {
        linkify: true,
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const { listingType, transactionProcessAlias, unitType } = publicData;
  if (!(listingType && transactionProcessAlias && unitType)) {
    // Listing should always contain listingType, transactionProcessAlias and unitType)
    return (
      <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} invalidListing />
    );
  }
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? ('booking' ? isPurchase : 'purchase') : 'inquiry';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && (processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected);
  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
  const authorSlug = createSlug(authorDisplayName);
  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const reviewAverage = currentListing?.attributes?.metadata?.reviewAverage
    ? currentListing.attributes.metadata.reviewAverage
    : 0;
  const reviewCount = currentListing?.attributes?.metadata?.reviewCount
    ? currentListing.attributes.metadata.reviewCount
    : 0;

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability = !currentListing.currentStock
    ? null
    : currentStock > 0
    ? 'https://schema.org/InStock'
    : 'https://schema.org/OutOfStock';

  const availabilityMaybe = schemaAvailability ? { availability: schemaAvailability } : {};

  const backLinkParams = {
    id: listingId.uuid,
    slug: listingSlug,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    tab: 'media',
  };

  const handlePublishListing = () => {
    onPublishListingDraft(currentListing.id.uuid)
      .then(response => {
        if (response.status === 200) {
          setRedirectAfterReview(true);
        } else {
          setPublishError(true);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const shareTitle = intl.formatMessage(
    { id: 'ListingPage.share.social.title' },
    { title, authorDisplayName }
  );

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          ...availabilityMaybe,
        },
      }}
    >
      {shouldRedirectAfterReview && <Redirect to={{ pathname: '/listings' }} />}

      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {currentListing.id && noPayoutDetailsSetWithOwnListing && !isPreview ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
              />
            ) : null}
            {/* {currentListing.id && !isPreview ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null} */}
            {isOwnListing && (
              <Modal
                id={'listing-page-review'}
                scrollLayerClassName={css.reviewModalScrollLayer}
                containerClassName={css.reviewModalContainer}
                contentClassName={css.reviewModalContent}
                isOpen={showReviewModal}
                onClose={() => {
                  setShowReviewModal(false);
                }}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div>
                  <div className={css.reviewModalTitle}>
                    <FormattedMessage id="ListingPage.review.modal.title" />
                  </div>
                  <div className={css.reviewModalImageContainer}>
                    <ResponsiveImage
                      rootClassName={css.reviewModalImage}
                      alt={'listing image'}
                      image={firstImage}
                      variants={variants}
                    />
                    <div className={css.reviewModalImageOverlay}></div>
                  </div>
                  <div className={css.reviewModalSubtitle}>
                    <FormattedMessage id="ListingPage.review.modal.subTitle" />
                  </div>
                  <p className={css.reviewModalText}>
                    <FormattedMessage id="ListingPage.review.modal.content" />
                  </p>
                </div>
                <div className={css.modalButtonBar}>
                  <NamedLink
                    className={css.backLink}
                    name="EditListingPage"
                    params={backLinkParams}
                  >
                    <IconArrowHead direction="left" size="big" rootClassName={css.arrowIcon} />
                    <FormattedMessage id="ListingPage.review.modal.backButton" />
                  </NamedLink>
                  <button
                    className={css.reviewListingLink}
                    onClick={e => {
                      e.preventDefault();
                      setShowReviewModal(!showReviewModal);
                    }}
                  >
                    <FormattedMessage id="ListingPage.reviewListing.button" />
                    <Eye className={css.viewListingIcon} />
                  </button>
                </div>
              </Modal>
            )}
            <Modal
              id={'listing-page-share'}
              isOpen={showShareModal}
              onClose={() => {
                setShowShareModal(false);
              }}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <FormattedMessage
                id="ListingPage.share.modal.title"
                values={{ listingTitle: richTitle, authorDisplayName: authorDisplayName }}
              />
              <div className={css.shareNetworkContainer}>
                <div className={css.shareNetwork}>
                  <FacebookShareButton url={productURL} className={css.shareButton}>
                    <IconFacebook className={css.shareIcon} />
                  </FacebookShareButton>
                </div>
                <div className={css.shareNetwork}>
                  <TwitterShareButton
                    url={productURL}
                    title={shareTitle}
                    className={css.shareButton}
                  >
                    <IconTwitterX className={css.shareIconCustom} />
                  </TwitterShareButton>
                </div>
                <div className={css.shareNetwork}>
                  <EmailShareButton
                    url={productURL}
                    subject={shareTitle}
                    body={''}
                    className={css.shareButton}
                  >
                    <IconEmail className={css.shareIcon} />
                  </EmailShareButton>
                </div>
                <div className={css.shareNetwork}>
                  <WhatsappShareButton
                    url={productURL}
                    title={shareTitle}
                    separator=":: "
                    className={css.shareButton}
                  >
                    <IconWhatsApp className={css.shareIconCustom} />
                  </WhatsappShareButton>
                </div>
                <div
                  className={css.shareCopyToClipboardContainer}
                  style={{ display: 'flex', alignItems: 'center', gap: '8px', marginTop: '20px' }}
                >
                  <input
                    value={productURL}
                    disabled={true}
                    style={{ padding: '8px', fontSize: '16px', flex: 1 }}
                  />
                  <CopyToClipboard
                    text={productURL}
                    onCopy={() => {
                      toast('Copied to clipboard');
                    }}
                  >
                    <button style={{ padding: '8px', fontSize: '16px' }}>
                      <Copy style={{ fill: 'none' }} />
                    </button>
                  </CopyToClipboard>
                </div>
                {isOwnListing && (
                  <div className={css.providerSocialShareWrapper}>
                    <ProviderSocialShareCallout profileOrListingId={listingId?.uuid} intl={intl} />
                  </div>
                )}
              </div>
            </Modal>
            <Modal
              id="ListingPage.inquiry"
              contentClassName={css.inquiryModalContent}
              isOpen={inquiryModalOpen}
              onClose={() => setInquiryModalOpen(false)}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <InquiryForm
                className={css.inquiryForm}
                submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendInquiryError={sendInquiryError}
                onSubmit={onSubmitInquiry}
                inProgress={sendInquiryInProgress}
                isNegotiatedListing={isNegotiatedBookingProcess(processName)}
                messagePlaceholder={
                  isNegotiatedBookingProcess(processName)
                    ? intl.formatMessage(
                        {
                          id: 'NegotiatedInquiryForm.messagePlaceholder',
                        },
                        { authorDisplayName }
                      )
                    : intl.formatMessage(
                        {
                          id: 'InquiryForm.messagePlaceholder',
                        },
                        { authorDisplayName }
                      )
                }
              />
            </Modal>
            {isPreview && isOwnListing && !showReviewModal && (
              <div className={css.stickyButtons}>
                {publishError && (
                  <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
                )}
                <NamedLink className={css.backLink} name="EditListingPage" params={backLinkParams}>
                  <IconArrowHead direction="left" size="big" rootClassName={css.arrowIcon} />
                  <FormattedMessage id="ListingPage.review.modal.backButton" />
                </NamedLink>
                <button
                  className={css.completeLink}
                  onClick={e => {
                    e.preventDefault();
                    handlePublishListing();
                  }}
                >
                  <FormattedMessage id="ListingPage.review.modal.completeButton" />
                  <IconArrowHead
                    direction="right"
                    size="big"
                    rootClassName={css.completeArrowIcon}
                  />
                </button>
              </div>
            )}
            <div className={css.listingHeadingDesktop}>
              <div className={css.headerLeft}></div>
              <div className={css.headerCenter}>
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
                <p>
                  <NamedLink
                    name="ProfilePageWithSlug"
                    params={{ id: currentListing.author.id.uuid, slug: authorSlug }}
                  >
                    <FormattedMessage
                      id="ListingPage.authorName"
                      values={{ name: authorDisplayName }}
                    />
                  </NamedLink>
                </p>
              </div>
              <div className={css.headerRight}>
                <button
                  onClick={e => {
                    e.preventDefault();
                    setShowShareModal(true);
                  }}
                  title={intl.formatMessage({ id: 'ListingPage.heading.share.title' })}
                >
                  <Share />
                  <FormattedMessage id="ListingPage.heading.share" />
                </button>
                {!isOwnListing && (
                  <button
                    onClick={e => {
                      e.preventDefault();
                      onContactUser();
                    }}
                    title={intl.formatMessage(
                      { id: 'ListingPage.heading.message.title' },
                      { name: authorDisplayName }
                    )}
                  >
                    <MessageSquare />
                    <FormattedMessage id="ListingPage.heading.message" />
                  </button>
                )}
                {isOwnListing && (
                  <NamedLink
                    className={css.editListingButton}
                    name="EditListingPage"
                    params={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingPathParamType,
                      tab: listingTab,
                    }}
                    title={intl.formatMessage({ id: 'ListingPage.heading.edit.title' })}
                  >
                    <SquarePen />
                    <FormattedMessage id="ListingPage.heading.edit" />
                  </NamedLink>
                )}
              </div>
            </div>
            <div className={css.mobileIconsContainer}>
              <SectionGallery
                listing={currentListing}
                variantPrefix={config.layout.listingImage.variantPrefix}
                isProfile={false}
              />
              <div className={css.mobileIcons}>
                <button
                  onClick={e => {
                    e.preventDefault();
                    setShowShareModal(true);
                  }}
                  title={intl.formatMessage({ id: 'ListingPage.heading.share.title' })}
                >
                  <Share />
                </button>
                {!isOwnListing && (
                  <button
                    onClick={e => {
                      e.preventDefault();
                      onContactUser();
                    }}
                    title={intl.formatMessage(
                      { id: 'ListingPage.heading.message.title' },
                      { name: authorDisplayName }
                    )}
                  >
                    <MessageSquare />
                  </button>
                )}
                {isOwnListing && (
                  <NamedLink
                    className={css.editListingButton}
                    name="EditListingPage"
                    params={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingPathParamType,
                      tab: listingTab,
                    }}
                    title={intl.formatMessage({ id: 'ListingPage.heading.edit.title' })}
                  >
                    <SquarePen />
                  </NamedLink>
                )}
              </div>
            </div>
            <div className={css.listingContainer}>
              <div className={css.overview}>
                <div className={css.listingHeading}>
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                  <p>
                    <NamedLink
                      name="ProfilePageWithSlug"
                      params={{ id: currentListing.author.id.uuid, slug: authorSlug }}
                    >
                      <FormattedMessage
                        id="ListingPage.authorName"
                        values={{ name: authorDisplayName }}
                      />
                    </NamedLink>
                  </p>
                </div>

                <div className={css.listingDetailsOverview}>
                  {/* <h1>{richTitle}</h1> */}
                  <div
                    className={css.reviewContainer}
                    onClick={e => {
                      e.preventDefault();

                      const margin = 6;
                      const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
                      const isMobileLayout = hasMatchMedia
                        ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
                        : true;

                      const element = document.getElementById('reviews');
                      const topbarOffset = isMobileLayout
                        ? TOPBAR_HEIGHT_MOBILE
                        : TOPBAR_HEIGHT_DESKTOP;
                      const position = element.getBoundingClientRect().top;
                      const offsetPosition = position + window.scrollY - topbarOffset - margin;

                      window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                      });
                    }}
                  >
                    <ReviewRating rating={reviewAverage} reviewRatingClassName={css.reviewRating} />
                    <div className={css.reviewDivider}></div>
                    <div className={css.reviewCountSummary}>
                      <div className={css.reviewCount}>{reviewCount}</div>
                      <div>
                        <FormattedMessage
                          id="ProfilePage.reviews"
                          values={{ count: reviewCount }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={css.sectionDetails}>
                    <Heading as="h2" rootClassName={css.sectionHeading}>
                      <FormattedMessage id="ListingPage.overviewDescription" />
                    </Heading>
                    <div className={css.detailsLarge}>{richDescription}</div>
                    {isVirtual && (
                      <div className={css.virtualLink}>
                        <span className={css.iconWrapper}>
                          <Video />
                        </span>
                        <div className={css.labelWrapper}>
                          <FormattedMessage id="ListingPage.virtualLink" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={css.orderColumnForProductLayout}>
                <OrderPanel
                  className={css.productOrderPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  onSubmit={handleOrderSubmit}
                  authorLink={
                    <NamedLink
                      className={css.authorNameLink}
                      name="ListingPage"
                      params={params}
                      to={{ hash: '#author' }}
                    >
                      {authorDisplayName}
                    </NamedLink>
                  }
                  title={
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  }
                  titleDesktop={
                    <H4 as="h1" className={css.orderPanelTitle}>
                      <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                    </H4>
                  }
                  payoutDetailsWarning={payoutDetailsWarning}
                  author={ensuredAuthor}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onContactUser={onContactUser}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  validListingTypes={config.listing.listingTypes}
                  marketplaceCurrency={config.currency}
                  dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                  marketplaceName={config.marketplaceName}
                />
              </div>
              <div className={css.listingDetails}>
                <CustomListingFields
                  publicData={publicData}
                  metadata={metadata}
                  listingFieldConfigs={listingConfig.listingFields}
                  categoryConfiguration={config.categoryConfiguration}
                  intl={intl}
                />
                <SectionRequirementsMaybe publicData={publicData} intl={intl} />
                {listingConfig.listingFields.reduce((pickedElements, config) => {
                  const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;
                  const listingType = publicData?.listingType;
                  const isTargetListingType =
                    includeForListingTypes == null || includeForListingTypes.includes(listingType);

                  const value =
                    scope === 'public'
                      ? publicData[key]
                      : scope === 'metadata'
                      ? metadata[key]
                      : null;
                  const hasValue = value != null;
                  return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                    ? [
                        ...pickedElements,
                        <SectionMultiEnumMaybe
                          key={key}
                          heading={config?.showConfig?.label}
                          options={createFilterOptions(enumOptions)}
                          selectedOptions={value || []}
                        />,
                      ]
                    : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                    ? [
                        ...pickedElements,
                        <SectionTextMaybe
                          key={key}
                          heading={config?.showConfig?.label}
                          text={value}
                        />,
                      ]
                    : pickedElements;
                }, [])}
                <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                  mapsConfig={config.maps}
                />
                <SectionMeetingInstructionsMaybe publicData={publicData} intl={intl} />
                <SectionLocationDescriptionMaybe publicData={publicData} intl={intl} />
                <SectionAdditionalInfoMaybe publicData={publicData} intl={intl} />
              </div>
            </div>
            {/* <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            />*/}
            <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  onPublishListingDraft: func.isRequired,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onPublishListingDraft: listingId => dispatch(requestPublishListingDraft(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
